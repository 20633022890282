@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }
}

@layer utilities {
  .scrollbar-hidden {
    scrollbar-width: none;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .shadow-all {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }

  .shadow-top-with-x {
    box-shadow: rgba(0, 0, 0, 0.07) 0px -1px 10px;
  }

  .shadow-bottom-with-x {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 10px;
  }
}